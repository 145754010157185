import { render, staticRenderFns } from "./User.vue?vue&type=template&id=2f44aedc&scoped=true"
import script from "./User.vue?vue&type=script&lang=js"
export * from "./User.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f44aedc",
  null
  
)

export default component.exports